import React from 'react';
import { ReactComponent as FbLogo } from '../svg/fb.svg'
import { ReactComponent as InstLogo } from '../svg/instagram.svg'
import { ReactComponent as TwiLogo } from '../svg/twitter.svg'
import { ReactComponent as VKLogo } from '../svg/vk.svg'
import { ReactComponent as YTLogo } from '../svg/youtube.svg'
import { ReactComponent as TGLogo } from '../svg/telegram.svg'

function Social() {
  return (
    <div className='social'>
      <a
         href='https://t.me/psycheagroup'
         target='_blank'
         title='Telegram'
         rel='noopener noreferrer'>
        <TGLogo title='Telegram' />
      </a>
      <a
         href='https://www.instagram.com/psyshit_com/'
         target='_blank'
         title='Instagram'
         rel='noopener noreferrer'>
        <InstLogo title='Instagram' />
      </a>
      <a
         href='https://vk.com/psycheagroup'
         target='_blank'
         title='VK'
         rel='noopener noreferrer'>
        <VKLogo title='VK' />
      </a>
      <a
         href='https://www.youtube.com/channel/UCPYuOanAxL2mgWIUUChXlww'
         target='_blank'
         title='YouTube'
         rel='noopener noreferrer'>
        <YTLogo title='YouTube' />
      </a>
      <a
         href='https://www.facebook.com/psihea/'
         target='_blank'
         title='Facebook'
         rel='noopener noreferrer'>
        <FbLogo title='Facebook' />
      </a>
      <a
         href='https://twitter.com/dporubovpsy'
         target='_blank'
         title='Twitter'
         rel='noopener noreferrer'>
        <TwiLogo title='Twitter' />
      </a>
    </div>
  )
}

export default Social;

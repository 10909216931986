import React from 'react'
import { shows } from '../data/shows'

function getDateString(date) {
  const dateObj = new Date(date)
  const year = dateObj.getFullYear()

  let month = dateObj.getMonth() + 1
  if (month < 10) month = `0${month}`

  let day = dateObj.getDate()
  if (day < 10) day = `0${day}`

  // <span>{`${day}.${month}`}<span className='shows__year'>{`.${year}`}</span></span>

  return (
    <span>{`${day}.${month}`}</span>
  )
}

function List() {
  // const currentShows = shows.filter((show) => new Date(show.date) - new Date() >= 0)

  return (
      <div className='list'>
        {
          shows.map((show, i) => {
            const {
              date,
              project,
              place,
              city,
              country,
              countryShort,
              eventLink,
              ticketLink,
              tba } = show

            let ticketElement = null
            let eventElement = null

            if (ticketLink) {
              ticketElement =
                <div>
                  <a href={ticketLink} className='list__btn list__btn--tickets' target='_blank' rel='noopener noreferrer'>
                    БИЛЕТЫ
                  </a>
                </div>
            } else {
              ticketElement =
                <div>
                  <button disabled className='list__btn list__btn--tickets list__btn--disabled'>
                    БИЛЕТЫ
                  </button>
                </div>
            }

            if (eventLink) {
              eventElement =
                <div>
                  <a href={eventLink} target='_blank' className='list__btn' rel='noopener noreferrer' >
                    ВСТРЕЧА
                  </a>
                </div>
            } else {
              eventElement =
                <div>
                  <button className='list__btn list__btn--disabled'>
                    ВСТРЕЧА
                  </button>
                </div>
            }

            const cityFinal = countryShort ? `${city} (${countryShort})` : city

            return (
              <div className='list__item' key={date + city}>
                  <div className='list__info'>

                    <div className='list__partone'>
                      <div className='list__date'>
                        {
                          tba? 'TBA' : getDateString(date)
                        }
                      </div>

                      <div className='list__parttwo'>
                        <div className='list__city'>
                          {cityFinal}
                        </div>
                      </div>

                      <div className='list__place'>
                        @ {place}
                      </div>
                    </div>

                  <div>
                    <div className='list__project'>
                      {project}
                    </div>


                  </div>

                  </div>

                  <div className='list__btns'>
                    {ticketElement}
                    {eventElement}
                  </div>
              </div>
            )
          })
        }
      </div>
  )
}

export default List

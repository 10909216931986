import firebase from 'firebase/compat/app';

export const appName = 'psyshit-999';

const config = {
  apiKey: 'AIzaSyBbuTDp6w_rFfwsudlzEhmi8y0-MhRD-zw',
  authDomain: `${appName}.firebaseapp.com`,
  databaseURL: `https://${appName}.firebaseio.com`,
  projectId: appName,
  storageBucket: `${appName}.appspot.com`,
  messagingSenderId: '630373843125'
};

firebase.initializeApp(config);
